<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                角色授权
                <a-checkbox-group v-model:value="checkedlist"></a-checkbox-group>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            option1: [{ lable: '广告主', value: '1' }, { lable: '营销人员', value: '2' }, { lable: '流量平台', value: '3' }],
            menulist: [],
            ndoelist: [],
            checkedlist: [],
        }
    },
    methods: {
        get_role_node() {
            this.myaxios().then((res) => {
                this.checkedlist = res.data
            }).catch((err) => {
                console.log(err);
            });
        },
        get_menu() {
            this.myaxios('/menu/1/', 'get').then((res) => {
                console.log(res);
                this.ndoelist = res.data.menulist;
                this.menulist = res.data.ndoelist;
                this.get_role_node()
            }).catch((err) => {
                console.log(err);
            });
        },
    },
    beforeCreate() {

    },
    mounted() {
        if (this.$route.query.name == '商户资质提交') {
            this.$router.push({ name: 'merchant_commit', params: { 'name': this.$route.query.name } })
            console.log();
            // window.location.href = '/merchant_commit?name=' + this.$route.query.name
        }
        if (this.$route.query.name == '商户资质审核') {
            this.$router.push({ name: 'merchant_check', params: { 'name': this.$route.query.name } })
        }
        if (this.$route.query.name == '广告列表') {
            this.$router.push({ name: 'merchant_use', params: { 'name': this.$route.query.name } })
        }
    },
    created() {
        console.log(this.$route.query.name);
        // 商户资质提交
        // 商户资质审核
        // 广告列表

        // console.log(this.$router.params['name']);
    }
}
</script>

<style scoped>
@import url('../assets/style.css');

.aa {
    float: left;

}
</style>